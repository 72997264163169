.sizeimg img{
    width: 9em;
    height: 9em;
    transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.sizeimg img:hover{
    transform: scale(1.1);
    transition: transform.2s ease-in-out;
}

