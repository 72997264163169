.flock {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flock img{
    margin: 0;
    display: block;
    padding: 0;
    width: 80%;
    height: 80%;
    object-fit: cover; /* Garante que a imagem preencha a área da seção */
}