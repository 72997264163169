
.footer{
    width: 100vw;
    background-color: rgba(202, 202, 202, 1);
    color: #fff;
    padding: 1em;
    text-align: center;
    list-style: none;
    margin: 0;
}

.social_list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
}

.social_list li{
    margin: 0 1em;
}

.social_list svg{
    font-size: 1.5em;
    cursor: pointer;
}

.copy_right{
    margin-top: 0em;

}
.copy_right span{
    font-weight: bold;
    color: #1a2e16;
}

.networks p{
    font-size: small;
    color:rgb(119, 119, 119);
}

#insta{
    width: 2em;
    height: 2em;
    color: #DD2A7B;
}
#linkedin{
    width: 2em;
    height: 2em;
    color: #0E76A8;
}

