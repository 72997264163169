.navbar img{
    height: 5em;
    margin-right: 4em;
}


header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

nav {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    z-index: 1000;
    height: 9%; /* Define uma altura fixa */
    color: #86AB62;
    padding: .1em 0;
    transition: background-color 0.3s ease; /* Transição suave */
  }

.navbar {
  top: 0;
  left: 0;
  z-index: 1000; /* Garante que a navbar fique acima de outros elementos */
  transition: background-color 0.3s ease; /* Transição para uma mudança suave */
}
.navbar button{
  font-size: 1em;
}
  .scrolled {
    background-color: rgba(135, 171, 98, 0.8); /* Cor de fundo quando a página for rolada */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra opcional para efeito de separação */
  }

  #logopastopraxi{
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  ul {
    display: flex;
    justify-content: center; /* Centraliza os itens horizontalmente */
    align-items: center; /* Centraliza os itens verticalmente */
    display: flex;
    gap: auto;
    list-style: none;
    
  }


  .item button{
    cursor: pointer;
    background-color: transparent;
    color: #333;
    border: none;
    font-weight: bold;
  } 

  #menu_icon{
    display: none;
  }
  

  .showMenu + #menu_icon{
    color: #4b2a2a;
  }
  
 

  .item button:hover {
    color:#86AB62;
    cursor: pointer;
  }

  .scrolled .item button:hover{
  color:antiquewhite
  }

  .item {
    margin: 0 15px;
    
  }
  
  .item a {
    color: #333;
    text-decoration: none;
    font-weight: bold;
  }
  
  .item a:hover {
    color: #86AB62;
  }
  

  @media only screen and (max-width : 768px){
    
    nav ul{
      display: none;
    }
    #menu_icon {
      display: block;
      color: #86AB62;
      position: absolute;
      cursor: pointer;
      background-color: transparent;
      border: none;
      right: 1.3em;
      top: 1.3em;
    }
    #menu_icon buton{
      width: 13em;
    }
    .showMenu{
      display: flex;
      width: 100%;
      padding-top: 1.7em;
      background-color: #86AB62;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0 8px 10px rgba(0, 0, 0, 0.3);
    }
    .showMenu button{
      width: 10em
    }

    #TiThMenu{
      color:#333;
      width: 3.5em;
      height: 3.5em;
    }

    #logopastopraxi{
      position: absolute;
      left: .2em;
      top: .2em;
    }
    .showMenu li, .showMenu button{
      font-size: 1.5em;
    }
    .scrolled{
      position: static;
      width: 100%;
      height: 6em;
      background-color:#86AB62;
      box-shadow: none;
    }
    .scrolled ul{
      padding-top: 13em;
    }

    .item button:hover {
      color:antiquewhite;
    }
    
  }

  .toggle {
    position: relative;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition-duration: .3s;
  }
  
  .bars {
    width: 100%;
    height: 4px;
    background-color: rgb(76, 189, 151);
    border-radius: 5px;
    transition-duration: .3s;
  }
  
  #checkbox:checked + .toggle .bars {
    margin-left: 13px;
  }
  
  #checkbox:checked + .toggle #bar2 {
    transform: rotate(135deg);
    margin-left: 0;
    transform-origin: center;
    transition-duration: .3s;
  }
  
  #checkbox:checked + .toggle #bar1 {
    transform: rotate(45deg);
    transition-duration: .3s;
    transform-origin: left center;
  }
  
  #checkbox:checked + .toggle #bar3 {
    transform: rotate(-45deg);
    transition-duration: .3s;
    transform-origin: left center;
  }
  