.container {
    display: flex;
    justify-content: center;
    background-color:none;
    border: .2em solid rgba(0, 0, 0, 0.1);
    border-radius: 1em ;
    transition: transform 0.5s ease, box-shadow 0.5s ease;
}
.container:hover{
    transform: scale(1.1);
    transition-duration: .5s;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.title{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    color:antiquewhite;
    padding: 0 1em;
    margin: .1em;
    border-radius: 1em;
    font-size: 1em;
    max-width: 15em;
    min-width: 15em;
}

.title h1,.title p{
    color: antiquewhite;
}
.description{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.description ul{
    display: flex;
    color:#8a8989;
    padding: 0 2em;
    margin: .1em;
    text-align:left;
    font-size: 1.2em;
    border-radius: 1em;
    max-width: 30em;
    display: block; /* Garante que cada <li> ocupe uma linha */
}


.description p, .description li{
    font-size: 1em;
}

.image, .image img{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: .1em;
    border-radius: 1em;
}


@media  (max-width : 828px){
    .container {
        border: .2em solid rgba(0, 0, 0, 0.1);
        background-color: none;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 0 3vw;
    }
    .container:hover{
        transform: none;
        transition-duration: none;
        box-shadow: none;
    }
    .description ul{
        padding: 0 10vw;
    }
    
}

