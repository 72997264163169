.container{
    min-width: 15em;
    min-height: 10em;
    background-color:transparent ;
    border-radius: 1em;
    margin: 1em;
    border: .15em solid;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Garante que o conteúdo e a imagem fiquem em posições opostas */
    padding: 1em; /* Adiciona um espaçamento interno */
}

@media (max-width: 800px) {
    .container {
        margin: .5em 0 ;
    }
}

.container p{
    padding: 0;
    font-size: larger;
    font-size: 1.2em;
    color: #8a8989;
}

.container img{
    width: 2em;
    align-self: center; /* Centraliza horizontalmente a imagem */
}
.container:hover {
    background-color: #8a898970;
}
.container p:hover{
    color: beige;
}

