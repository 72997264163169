/* Centraliza o formulário e ajusta o tamanho conforme a largura da tela */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .contact_form {
    margin: 1em;
    width: 100%;
    max-width: 800px; /* Limita o tamanho máximo para telas maiores */
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  
  #form_button{
    background-color: #4CAF50;
  }
  
  .title h2{
    text-align: center;
    font-size: 3em;
    font-weight: bold;
    color: #8a8989; /* Cor do texto */
  }
  
  .flock img {
    width: 100%;
    height: auto;
    max-width: 2500px; /* Define um tamanho máximo opcional */
    object-fit: contain; /* Garante que a imagem se ajuste sem distorção */
    border-radius: .5em;
    margin-bottom: 20px;

  }
  
  /* Estilo para dispositivos móveis */
  @media (max-width: 768px) {
    .contact-form {
      padding: 15px;
      max-width: 100%;
      box-shadow: none; /* Remove sombra em telas menores para melhor visualização */
    }
  }
  
  /* Estilo para telas muito pequenas */
  @media (max-width: 480px) {
    .contact-form-container {
      padding: 10px;
    }
  }

  .cloud img{
    width: 40%;
    max-width: 300px;
}
  #cloud1,#cloud2 {
    opacity: 0.8; /* Um pouco de transparência */
    position: relative;
}
#cloud1{
    bottom: 3vh; /* Altura da nuvem no eixo vertical */
    animation: moveCloud1 40s linear infinite; /* Animação infinita */
}
#cloud2 {
    top: 1vh;
    animation: moveCloud2 50s linear infinite; /* Animação infinita */
}
  
  @keyframes moveCloud1 {
    0% {
        left: -20%; /* Começa fora da tela à esquerda */
    }
    50% {
        left: 55%; /* Se move até sair da tela à direita */
    }
    100% {
        left: -20%; /* Retorna para começar de novo */
    }
}
@keyframes moveCloud2 {
    0% {
        right: -20%; /* Começa fora da tela à esquerda */
    }
    50% {
        right: 80%; /* Se move até sair da tela à direita */
    }
    100% {
        right: -20%; /* Se move até sair da tela à direita */
    }
}