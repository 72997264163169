.container {
    padding: .5em;
}

.container {
    display: flex;
    flex-direction: column;
    font-size: large;
    text-align: center;
}

.box {
    display: flex;
    width: 13em;
    height: 3em;
    background-color: #8a8989;
    border-radius: .5em;
    text-align: center;
    justify-content: center;
    align-items: center; /* Centraliza verticalmente o conteúdo */
}

.box p{
    padding: 0;
    font-size:2.5em;
    color:aliceblue
}

