.container{
  width: 100vw;
  background-color: rgb(59, 78, 72);
  justify-content: center;
}


.title h2{
  text-align: center;
  font-size: 3em;
  font-weight: bold;
  color: #8a8989; /* Cor do texto */
}

.FlockNumbers {
  display: flex;
  justify-content: center; /* Alinha os itens no centro horizontalmente */
  align-items: center; /* Alinha os itens no centro verticalmente (se necessário) */
  max-width: 100em;
  flex-wrap: wrap;
  margin: 0 auto; /* Centraliza o contêiner dentro do seu elemento pai */
}

.leaflet{

  margin: 0 auto; /* Centraliza o contêiner dentro do seu elemento pai */
  max-width: 100em;
}
