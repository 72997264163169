.hero {
    width: 100%;
    height: 82vh; /* Define altura da seção como altura total da janela */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Oculta o que estiver fora dos limites */
    
}

.contentOne {
    position: absolute;
    z-index: 2; /* Garante que o texto fique acima do vídeo */
    text-align: center;
    color: white; /* Cor do texto para maior contraste */
}

.flock {
    position: absolute;
    left: 0;
    width: 100%;
    height: 80%;
    z-index: 1;
    overflow: hidden;
}

.flock video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.5) contrast(1.1);
    pointer-events: none; /* Impede a interação com o vídeo */
}

.flock img{
    display: none;
}

.texts {
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contentOne h1 {
    color: #8a8989; /* Cor do texto */
    font-size: clamp(.5em, 1.5vw + 1em, 2.5em);
}

.contentOne p {
    font-style: italic;
    font-size: 1.2em;
    color: #8a8989; /* Cor do texto */
    margin-top: 1em;

}


.typewriter {
    overflow: hidden;
    animation: typing 3s steps(40, end) forwards
}

/* Animação de digitação usando clip-path */
@keyframes typing {
    from {
        clip-path: inset(0 100% 0 0); /* Oculta todo o texto inicialmente */
    }
    to {
        clip-path: inset(0 0 0 0); /* Revela todo o texto */
    }
}

@media (max-width: 768px) {

    .flock video {
        display: none;
        margin-top: 0;
    }
    .flock img{
        margin: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; /* Garante que a imagem preencha a área da seção */
        filter: brightness(0.5) contrast(1.1);
    }
    .contentOne h1 {
        color: #8a8989; /* Cor do texto */
        font-size: clamp(.5em, 2vw + 1.5em, 2.5em);
    }
}




