.sectiondivider {
    margin: 0;
    position: relative;
    left: 0;
    padding: 0;
    border: 0;
    top: .3em;
  }

  :root {
    --color-bg: rgb(59, 78, 72);
  }

