.container{
    padding-top: 1em;
    width: 14em;
    height: 16em;
    background-color:none;
    display: flex; /* Flexbox para alinhar itens */
    flex-direction: column; /* Empilha os itens verticalmente */
    justify-content: center; /* Alinha o conteúdo verticalmente */
    align-items: center; /* Alinha o conteúdo horizontalmente */
    text-align: center; /* Centraliza o texto no conteúdo */
    padding: 0 1em 0 1em;
    
    
    
}

.circle{
    text-align: center;
    padding: 0;
    border: .5em solid;
    width: 12em;
    height: 12em;
    background-color:#D6E1D4;
    border-radius: 50%;
    border-color: rgb(65, 90, 65);
    transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.circle h2{
    font-size: 6em;
    color: #8a8989;;
}

.circle:hover{
    cursor:pointer;
    transform: scale(1.1);
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.text{
    text-align:right;
    min-width: 10em;
    min-height: 5em;
}

.container h3{
    color: #8a8989; /* Cor do texto */
    padding-top: .3em;
    text-align: center;
}

#icon{
    color: rgb(0, 0, 0);
}

@media (max-width: 446px) {
    .text{
        min-width: 10em;
        min-height: 0em;
    }
    .container{
        height: 15em;
    }
}