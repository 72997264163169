.title h2{
    text-align: center;
    font-size: 3em;
    font-weight: bold;
    color: #8a8989; /* Cor do texto */
  }
  
  .team{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 2em 0;
  }
  
  .partners{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 2em 0;
  }

  .Farmers ul {
    display: flex;
    flex-direction: column;
    list-style: none; /* Remove a bolinha padrão, se necessário */
    padding: 0;
    margin: 0;
    text-align: center;
    padding: 2em 0;
}

.Farmers li {
    display: flex;
    flex-grow: inherit;
    color: #8a8989;
    font-size: larger;
    margin-bottom: 0.5em; /* Adiciona espaço entre os itens */
}

#hat{
    width: 1.1em;
    height: 1.1em;
    color:rgba(163, 128, 104, .6);
    margin-right: .2em;
}