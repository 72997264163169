.container{
    padding-top: 1em;
    width: 15em;
    height: 16em;
    background-color:none;
    display: flex; /* Flexbox para alinhar itens */
    flex-direction: column; /* Empilha os itens verticalmente */
    justify-content: center; /* Alinha o conteúdo verticalmente */
    align-items: center; /* Alinha o conteúdo horizontalmente */
    text-align: center; /* Centraliza o texto no conteúdo */
    padding: 0 1em 0 1em;
}
.photo, .photo img{
    width: 12em;
    height: 12em;
    border-radius: 50%;
    background-color: #8a8989;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    transition: transform 0.5s ease, box-shadow 0.5s ease;
}
.text{
    min-width: 12em;
    min-height: 5em;
}
.photo:hover{
    transform: scale(1.1);
}

.container h3{
    color: #8a8989; /* Cor do texto */
    padding-top: .3em;
}

.container p{
    color: #8a8989; /* Cor do texto */
    padding-top: .3em;
    font-size: small;
}