.container{
  }
  .title{
    text-align: center;
  }
  .title p{
    padding: 0;
    margin-top: 0;
    font-size: small;
  }
.title h2{
  
  text-align: center;
  font-size: 3em;
  font-weight: bold;
  color: #8a8989; /* Cor do texto */
}

.test{
    width: 40em;
    height: 2em;
    background-color: blueviolet;
}

.outcomes{
  display: flex;
  justify-content:center;
  justify-items: center;
  align-items: center;
  flex-wrap: wrap;
}
