.itens {
    text-align: center;
    font-size: small;
    color:rgb(119, 119, 119);
    
}
.itens img {
    padding-top: .4em;
}

.itens img:hover{
    cursor: pointer;
}

.emptyText {

    margin-top: 12px; /* Garante o mesmo espaçamento da div de texto */
  }