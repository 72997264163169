.title h2{
    text-align: center;
    font-size: 3em;
    font-weight: bold;
    color: #8a8989; /* Cor do texto */
  }

.container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}
.down{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.down,.down img{
    width: 22%;
    opacity: .5;
}

@media  (max-width : 828px){
    .down, .down img{
        width: 30%;
    }
}
