

/* Para navegadores baseados em WebKit (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 2px; /* Largura da barra de rolagem vertical */
  height: 2px; /* Altura da barra de rolagem horizontal */
}

::-webkit-scrollbar-track {
  background: transparent; /* Remove o fundo da trilha da scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: rgb(65, 90, 65); /* Cor verde para o indicador (thumb) */
  border-radius: 20px; /* Bordas arredondadas no thumb */
  border: 3px solid transparent; /* Espaço ao redor do thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #008000; /* Verde mais escuro ao passar o mouse */
}
