.hero {
    justify-content: center;
    align-items: center;
    /*background-color: #edf7f2; /* Ou qualquer cor de fundo que preferir */
    background-color: none; /* Ou qualquer cor de fundo que preferir */
    
}

.hero h3, h1{
    text-align: center;
    font-size:larger;
    color: #6d6c6c;
}

.contentTwo {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    padding: 0 2%; /* Define uma largura máxima para o texto */
    max-width: 1300px;
}
.contentTwo img{
    width: 80%;
    height: auto;
}
.contentOBJ{
    display: flex;
    justify-content: space-between;
}

@media (max-width: 1000px) {
    .contentOBJ {
        flex-wrap: wrap;
    }
    .contentTwo img{
        width: 100%;
    }
}

.fct {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.fct a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10em;
}

.fct img {
    padding-top: 1em;
    min-width: 15em;
    width: 15%;
}

.fct p{
    font-size: 1.2em;
    color: #345a43; /* Cor do texto */
}

.fct p, #strong{
    font-weight: bold;
}

.fct p, #weak{
    font-weight: lighter;
    font-size: small;
}

.hero h1 {
    
    font-size: 3em;
    font-weight: bold;
    color: #8a8989; /* Cor do texto */
}

.onugrid{
    padding: 1em 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center ;
}



.grid-animate {
    opacity: 0; /* Começa invisível */
    transform: translateY(20px); /* Posiciona ligeiramente abaixo */
    animation: fadeInUp 0.8s ease forwards; /* Animação para subir e aparecer */
  }
  