.container {
    background-color: none;
    width: 10em;
    height: 17em;
    display: flex; /* Flexbox para alinhar itens */
    flex-direction: column; /* Empilha os itens verticalmente */
    justify-content: center; /* Alinha o conteúdo verticalmente */
    text-align: center; /* Centraliza o texto no conteúdo */
    padding: 0 1em 0 1em;
}

.photo, .photo img{
    transition: transform 0.5s ease, box-shadow 0.5s ease;
    background-color: #8a8989;
    width: 8em;
    height: 8em;
    border-radius: 50%;
}

.photo:hover{
    transform: scale(1.1);
}

.text {
    min-height: 8em;
}

.text h3,p{
    color: #8a8989; /* Cor do texto */
    padding-top: .3em;
}

.text p{
    font-size: small;
}

.text {

}