.work{
    position: relative;
    width: 100%;
    background-color: none;
    border-bottom: 2px solid #bbb;
}

.work h3{
    margin: 0;
    border: 0;
    color: #8a8989; /* Cor do texto */
    font-size: 1.5em;
}
.work p{
    color: #8a8989; /* Cor do texto */
}

.authors, .data {
    display: flex;
    color: #8a8989; /* Cor do texto */
}

#author, #data{
    font-weight: bold;
    padding-right: .3em;
}
#subtitle{
    font-size: 1.2em;
}

.link{
    justify-content: center;
    background-color: aliceblue;
    width: 3em;
    height: 3em;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 10px 20px -4px;
    transition: transform 0.5s ease, box-shadow 0.5s ease;
    position: absolute;
    bottom: 5%;
    left: 92%;
}

#linkimg{
    width: 3em;
    height: 3em;
}

.link:hover{
    z-index: 1000;
    transform: scale(1.1);
    background-color: gray;
}

.link:hover #msg{
    display: block;
}

#msg{
    align-content: center;
    text-align: center;
    border-radius: .2em;
    width: 3em;
    height: 2em;
    display: none;
    background-color: rgb(223, 219, 219);
}

