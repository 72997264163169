
.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container h2{
    text-align: center;
    font-size: 3em;
    font-weight: bold;
    color: #8a8989; /* Cor do texto */
  }
.title img, .title p{
    max-width: 60%;
    margin: 0 auto;
    height: auto;
    display: block
}


.description {
    text-align: justify;
    padding: 0 2%; /* Mantém espaçamento confortável em telas maiores */
    max-width: 1300px;
}

.mapMontesinho{
    width: 100%;
    margin: 0 auto; /* Centraliza o contêiner dentro do seu elemento pai */
}

@media only screen and (max-width : 600px){
    .title img{max-width: 90%;
    }
}