* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'open Sans', sans-serif;
  }
  
  html,body,#root{
    background-color: #D6E1D4;
    height: 100%;
    margin: 0;

  }

  footer {
    width: 100%;
    background-color: rgba(202, 202, 202, 1);
    color: #fff;
    text-align: center;
    padding: 1em;
    position: relative;
    bottom: 0;
  }

  p{
    line-height: 1.5;
    font-size: large;
  }