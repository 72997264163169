.container{
    width: 1000px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    flex-wrap: wrap;
}

.min-height{
    display: flex;
    justify-content: center;
    width: 100%; 
    margin-top: 6em;
}

.start{
    justify-content: flex-start;
}

.colums{
    flex-direction: column;
    justify-content:flex-start;
}